export type LoginFormType = {
  username: string;
  password: string;
};

export type ResetPasswordFormType = {
  password: string;
  confirmPassword: string;
};

export type LoginResponse = {
  token: string;
};


export class LoginForm implements LoginFormType {
  constructor(
    public username = '',
    public password = '',
  ) {
  }
}


export class ResetPasswordForm implements ResetPasswordFormType {
  constructor(
    public password = '',
    public confirmPassword = '',
  ) {
  }
}
